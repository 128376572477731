<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('myExams.myExams') }} 
                    
                    div.text-center.mx-auto
                        router-link.btn.btn-outline-dark(to="/student/ActivateExam") {{ $t('studentHome.examActivate') }} 
                            svg.feather.feather-power(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                path(d="M18.36 6.64a9 9 0 1 1-12.73 0")
                                line(x1="12" y1="2" x2="12" y2="12")

                    div.text-center.mx-auto
                        router-link.btn.btn-outline-dark(to="/student/JoinToGroup") {{ $t('myExams.jointGroup') }}
                            svg.feather.feather-power(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                path(d="M18.36 6.64a9 9 0 1 1-12.73 0")
                                line(x1="12" y1="2" x2="12" y2="12")                                
                        
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/student/studenthome") {{ $t('studentHome.studentPanel') }} 
                                li.breadcrumb-item.active(aria-current="page") {{ $t('myExams.myExams') }} 

                div.row.mt-5.pb-5
                    div.col-lg-12.col-md-12.layout-spacing
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                        h4
                                            svg.bi.bi-ui-checks-grid(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                                path(d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z")
                                            template {{ $t('myExams.myExams') }}

                                div.widget-content.widget-content-area
                                    div.table-responsive
                                        table.table.table-bordered.table-hover.mb-4
                                            thead
                                                tr
                                                    th {{ $t('myExams.exam') }}
                                                    th {{ $t('myExams.code') }}
                                                    th {{ $t('myExams.date') }}
                                                    th {{ $t('myExams.score') }}
                                                    th {{ $t('myExams.status') }}
                                                    th {{ $t('myExams.result') }}
                                                    th {{ $t('myExams.action') }}
                                                    th {{ $t('myExams.badge') }}
                                                    th {{ $t('myExams.certificate') }}
                                           
                                            tbody
                                                tr(v-for="exam in examsList")
                                                    td {{ exam.sExamName }}
                                                    td {{ exam.sAccessCode }}                                        
                                                    td {{ exam.dUTCPresentationDate }}
                                                    td {{ exam.iScore }}
                                                    td {{ exam.sExamStatus}} 
                                                        template(v-if="exam.idExamStatus == 2") ({{exam.iOportunities}})
                                                    td 
                                                        span.badge.outline-badge-danger {{ exam.sResult }}
                                                    td
                                                        button.btn.btn-sm.btn-success(type="button" v-if="exam.idExamStatus == 1 || (exam.idExamStatus == 2 && exam.iOportunities < 3)" @click="goToExam(exam.sPath, exam.idPersonExam, exam.idGroupDetail, exam.sExamName, exam.idExam)") {{ $t('myExams.introduce') }}
                                                        button.btn.btn-sm.btn-success(style="margin: 5px" type="button" v-if="exam.idExamStatus == 3 " @click="$router.push('/student/results/' +  exam.idPersonExam + '/' + exam.idGroupDetail )") {{ $t('myExams.result') }}
                                                        //button.btn.btn-sm.btn-success(style="margin: 5px" type="button" v-if="exam.idExamStatus == 3 " @click="$router.push('/student/detailResults/' +  exam.idPersonExam + '/' + exam.idGroupDetail )") {{ $t('myExams.details') }}
                                                    td ...
                                                    td.text-center
                                                        a( v-if="exam.sResult != ''" @click="GetCertificate(exam.idPersonExam, exam.idGroupDetail)")
                                                            svg.bi.bi-patch-check(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                                                path(fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z")
                                                                path(d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z")
                                    


</template>

<script>
/* eslint-disable */
export default {
    name: 'MyExams',
    components: {
    },
    data() {
        return {
            examsList: [],
        }
    },
    methods: {
        async getMyExams() {
            await axios.get(apiURL + 'Student/getUserExams?idPerson='+ localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.examsList = response.data.resultList
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        },
        async prepareExam(id)
        {
            await axios.post(apiURL + 'Student/setIncompleteExam?id=' + id, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                console.log('Examne Marcado')
            } )
            .catch( (error) => {
                console.log('Error en el marcado del examen: ' + error)
            } )
        },
        goToExam(sUrl, idPersonExam, idGroupDetail, sCourseName, idExam) {
            Swal.fire({
            title: this.$t('msg.title')+ sCourseName +'?',
            html: this.$t('msg.html')+"<br/>"+ this.$t('msg.html1')+":" + JSON.parse(localStorage.getItem("dataPerson")).sName + " " + JSON.parse(localStorage.getItem("dataPerson")).sLastName + " <br/> "+ this.$t('msg.email') + JSON.parse(localStorage.getItem("dataPerson")).sEmail,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('msg.buttoacept'),
            cancelButtonText: this.$t('msg.buttodecli')
            }).then((result) => {
            if (result.isConfirmed) {
                if (sUrl == ''){
                    if(idPersonExam !== null){
                        this.prepareExam("P"+idPersonExam)
                    }
                    else if(idGroupDetail !== null){
                        this.prepareExam("G"+idGroupDetail)
                    }

                    router.push('/student/CoverPage/' + idPersonExam + '/' + idGroupDetail + '/' + idExam)
                }
                else{
                    if(idPersonExam !== null){
                        localStorage.id = "P"+idPersonExam
                        this.prepareExam("P"+idPersonExam)
                        router.push('/student/takeExam/' + sUrl)
                    }
                    else if(idGroupDetail !== null){
                        localStorage.id = "G"+idGroupDetail
                        this.prepareExam("G"+idGroupDetail)
                        router.push('/student/takeExam/' + sUrl)
                    }
                }
            }
            })            
        },
            async GetCertificate(pIdPersonExam, pIdGroupDetail) {
                if(pIdGroupDetail == null)
                {
                    pIdGroupDetail = 0
                }

                if(pIdPersonExam == null)
                {
                    pIdPersonExam = 0
                }


                const routess = this.$router.resolve({
                    name: 'Certificate',
                    params: {idPersonExam: pIdPersonExam, idGroupDetail: pIdGroupDetail},   
                });
                window.open(routess.href, '_blank');

                //router.push('certificate/' + idPersonExam + '/' + idGroupDetail);
            }
        //async GetCertificate(idPersonExam, idGroupDetail) {
        //    await axios.get(apiURL + 'Student/getCertificate?idPersonExam='+ idPersonExam + "&idGroupDetail=" + idGroupDetail, { headers: {
        //        'Content-Type': 'application/json',
        //        Authorization: 'Bearer ' + localStorage.userToken
        //    } })
        //    .then( (response) => {
        //        if(response.data.sErrors.length > 0)
        //        {
        //            Swal.fire({
        //                icon: 'error',
        //                title: 'Error',
        //                text: response.data.sErrors[0],
        //                footer: ''
        //            })
        //            return
        //        }




                //let pdfWindow = window.open("")
                //pdfWindow.document.write("<embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data.base64Report)+"' type='application/pdf'></embed>")
                
                //window.open("data:application/pdf;base64," + atob(response.data.base64Report));
                //console.log(atob(response.data.base64Report))

                //this.examsList = response.data.resultList
        //    } )
        //    .catch( (error)  => {
        //        console.log(error)
        //    } )            

        //}

    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.getMyExams()
    }
        

}
</script>                                                              